.kpi-background {
  background-color: #f5f5f9 !important;
  position: relative;
  width: 94%;
  padding: 5px 25px 5px 25px !important;
  margin: 15px !important;
  max-height: 80vh;
  border-radius: 12px;
  overflow: auto;
  margin-top: 90px !important;
}

.kpi-flex-wrap {
  flex-wrap: wrap !important;
}

.kpi-header-margin {
  margin-top: 20px !important;
}

.kpi-card-gap {
  gap: 70px !important;
  margin-left: 40px !important;
}

.date-picker-labels {
  color: var(--kpi-analysis-font-color) !important;
  font-weight: bold !important;
  text-align: left !important;
  font-size: 13px !important;
  font-family: var(--font-family) !important;
}

.kpi-cards-div-style {
  background-color: white;
  border-radius: 15px !important;
  height: 140px !important;
  width: 220px !important;
}

.kpi-card-header {
  color: var(--kpi-analysis-font-color) !important;
  font-weight: 550 !important;
  margin-top: 20px !important;
}

.kpi-card-text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 30px !important;
}

.date-error-text {
  color: var(--common-error-color) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  text-align: left !important;
}

.date-field-style {
  color: white !important;
  width: 260px !important;
  min-width: 260px !important;
}

.errordate.error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.error-box-style {
  height: 20px !important;
}

.details-cards-style {
  background-color: white;
  border-radius: 15px !important;
  height: 43vh !important;
  width: 80% !important;
  max-width: 750px !important;
  min-width: 300px !important;
  padding: 7px !important;
  margin-top: 30px !important;
  margin-bottom: 25px !important;
}

.select-stage-text-style {
  margin-top: 130px !important;
  font-family: var(--font-family) !important;
  color: var(--kpi-analysis-font-color) !important;
  font-weight: 550 !important;
}

.details-header {
  padding: 25px 0 0 25px !important;
  text-align: left !important;
  font-weight: bold !important;
  color: var(--kpi-analysis-font-color) !important;
}

.details-sub-header {
  color: #808080 !important;
  font-size: 13px !important;
  margin-left: 5px !important;
}

.details-sub-header-error {
  color: var(--common-error-color) !important;
}

.has-missing-data-dot-error {
  background-color: var(--common-error-color) !important;
}

.kpi-details-table-style {
  padding: 0 0 0 25px !important;
  height: 35vh !important;
  width: 90% !important;
  max-width: 750px !important;
  min-width: 300px !important;
  overflow: hidden;
  margin-left: -7px !important;
}

.kpi-details-table-style:hover {
  overflow: auto;
  padding-right: 0px;
}

.remove-border-bottom {
  border-bottom: none !important;
}

.kpi-table-cell-padding {
  padding: 10px !important;
}

.date-remove-icon {
  margin-top: 15px !important;
  margin-left: 3px !important;
}

.kpi-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.kpi-flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.date-fields-div {
  align-items: "center" !important;
}

.date-field-overall-grid {
  gap: 10px !important;
}

.kpi-details-grid {
  gap: 30px !important;
}
