.remove-filtered-date-icon {
  margin-top: 2px !important;
  margin-left: 15px !important;
}

.no-data-available-text {
  font-family: var(--font-family) !important;
  font-size: 16px !important;
  margin-top: 150px !important;
}

.date-field-style-margin {
  margin-left: 20px !important;
}

.stock-rhs-content-table-width {
  width: auto !important;
  overflow-x: auto !important;
}

.stock-update-error-text {
  font-family: var(--font-family) !important;
  color: var(--common-error-color) !important;
  font-size: var(--font-size) !important;
  font-weight: bold !important;
  text-align: center !important;
}

.no-stock-text-font {
  font-family: var(--font-family) !important;
}
