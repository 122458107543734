.payment-grid {
  border-right: var(--common-border-style) !important;
  height: 100% !important;
}

.bold-text {
  font-weight: bold;
}

.payment-header-text {
  display: flex;
  margin-bottom: 8px;
  text-align: left !important;
  margin-left: 30px !important;
  margin-top: 20px !important;
}

.colon-style {
  margin: 0 8px;
  min-width: 10px;
  text-align: left;
}

.payment-details-style {
  color: black !important;
  margin-left: 10px !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
}

.payment-header-text-label {
  width: 150px;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  color: var(--common-color);
  font-weight: bold !important;
}

.payment-details-tab-height {
  height: calc(100vh - 12.5rem);
}

.bill-details-list-style {
  font-family: var(--font-family) !important;
}

.payment-bill-modal-labels {
  margin-top: 13px !important;
}

.payment-bill-modal-text {
  font-family: var(--font-family) !important;
  padding-left: 10px !important;
  font-weight: bold !important;
}

.payment-bill-modal-cashIcon {
  width: 17px !important;
  height: 17px !important;
  color: black !important;
  margin-top: 3px !important;
}

.payment-details-modal-table {
  padding: 20px !important;
}

.modal-payment-details-style {
  width: 315px !important;
}

.payment-icon-style {
  width: 17px !important;
  height: 17px !important;
  margin-right: 5px !important;
  margin-top: 2px !important;
}

.payment-modal-icon-color {
  filter: brightness(0) saturate(100%) invert(25%) sepia(100%) saturate(500%)
    hue-rotate(220deg) !important;
}

.modal-table-header-style {
  color: #5143ed !important;
  font-family: var(--font-family) !important;
  font-weight: bold !important;
  font-size: var(--font-size) !important;
}

.remove-border-bottom {
  border-bottom: none !important;
}

.black-icon {
  filter: brightness(0) invert(0) !important;
}

.payment-currency-icon {
  vertical-align: middle !important;
}

.custom-currency-icon {
  font-size: 17px !important;
  margin-bottom: 2px !important;
}

.billList-currency-icon {
  font-size: var(--font-size) !important;
  margin-bottom: 2px !important;
}

.bill-modal-divider {
  border-bottom: var(--common-border-style) !important;
}

.payment-tab-height {
  max-height: calc(85vh - 13.8rem) !important;
}

@media screen and (min-width: 1950px) {
  .payment-tab-height {
    max-height: calc(85vh - 8.5rem) !important;
  }
}

.payment-list {
  width: 100%;
}

@media screen and (min-width: 1950px) {
  .payment-list {
    width: 80% !important;
  }
}

.payment-modal-action-button {
  margin-top: 5px !important;
}

.payment-modal-info-icon {
  width: 31px !important;
  height: 18px !important;
  color: #5143ed !important;
  cursor: pointer !important;
  transform: translateY(20%) !important;
}

.payment-details-modal {
  position: absolute !important;
  background-color: #fdfdfd !important;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  z-index: 1002 !important;
  width: 315px !important;
  left: 316px !important;
  top: 130px !important;
  padding-left: 8px !important;
}

.payment-details-modal-header-sticky {
  position: fixed !important;
  background-color: #ffffff !important;
}

.payment-details-modal-info-width {
  width: 140px !important;
}

.payment-details-modal-scroll {
  max-height: 150px !important;
  overflow: hidden !important;
  padding-top: 50px !important;
}

.payment-details-modal-scroll:hover {
  overflow-y: auto !important;
}

.bill-modal-deduction-input-padding-left {
  padding-left: 6px !important ;
}

.bill-modal-deduction-input-radio-button {
  margin-top: 9px !important;
}

.bill-modal-deduction-input-radio-button-label {
  font-family: var(--font-family) !important;
  font-weight: 400 !important;
  font-size: var(--font-size) !important;
  line-height: 16px !important;
}

.bill-modal-header {
  margin-top: -34px !important;
  margin-left: 35px !important;
}

.bill-modal-close-iconbutton {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  color: grey !important;
}

.edit-bill-rhs-list-margin {
  margin-left: 5px !important;
}
