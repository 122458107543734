.timeline-container {
  margin-top: 20px !important;
  padding: 20px !important;
  border-radius: 8px !important;
  margin-left: -10px !important;
}

.rct-horizontal-scroll {
  overflow-x: auto !important;
  scrollbar-width: thin;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: #def7ec !important;
  color: #03543f !important;
}

.react-calendar-timeline .rct-header-root {
  background-color: #def7ec !important;
  color: #03543f !important;
}

.react-calendar-timeline .rct-sidebar {
  background-color: #def7ec !important;
  color: #03543f !important;
}

.react-calendar-timeline .rct-dateHeader {
  background-color: #def7ec !important;
  color: #03543f !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(0, 0, 0, 0) !important;
}

.gantt-item-text {
  text-align: center !important;
  flex: 1 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
  font-size: 0.8rem !important;
  line-height: 28px !important;
}

.timeline-tab-height {
  max-height: calc(85vh - 6.5rem) !important;
}

@media screen and (min-width: 1950px) {
  .timeline-tab-height {
    max-height: calc(85vh - 1.5rem) !important;
  }
}

.selected-gantt-item-style {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 10;
}

.gantt-item-table-container {
  padding: 0 10px 10px 10px !important;
  width: 650px !important;
  max-height: 250px !important;
  overflow: auto !important;
}

.gantt-item-table-padding {
  padding: 5px !important;
}

.bold-text {
  font-weight: bold;
}

.timeline-legend-stack-outer {
  display: flex !important;
  justify-content: flex-end !important;
  margin-right: 19px !important;
  align-items: center !important;
}

.timeline-legend-text {
  font-family: var(--font-family) !important;
}

.timeline-modal-margin {
  padding-top: 10px !important;
  padding-left: 20px !important;
}
